import React from "react";
import PropTypes from "prop-types";
import { addToSecondSheet } from "../services/sheetService";
import "./style.css";

/**
 * Composant Confirmation : Permet à l'utilisateur de confirmer ou de refuser.
 * @param {object} user - Les informations de l'utilisateur trouvé.
 * @param {function} onBack - Fonction pour retourner au formulaire.
 */
const Confirmation = ({ user, onBack }) => {
  const handleResponse = async (response) => {
    try {
      const result = await addToSecondSheet(user, response);

      if (result.alreadyConfirmed) {
        alert("Vous avez déjà confirmé votre participation.");
      } else {
        alert(`Votre réponse "${response}" a été enregistrée.`);
      }

      onBack();
    } catch (err) {
      alert("Erreur lors de l'enregistrement. Veuillez réessayer.");
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card shadow p-4">
            <div class="row">
              <div class="col-4">
                <img id="logoInch" src="img/inchclass.png" alt="InchClass" />
              </div>
              <div class="col-6">
                <img id="logoOif" src="img/oif.png" alt="" />
              </div>
            </div>

            <h4 className="text-center mb-4" style={{ color: "#632b84" }}>
              Formations professionnelles gratuites aux métiers du numerique
              offertes par l’organisation internationale de la francophonie
              (OIF)
            </h4>

            {/* Titre */}
            <h2 className="text-center mb-4" style={{ color: "#1371b9" }}>
              Vos informations
            </h2>

            {/* Informations utilisateur */}
            <div className="mb-4">
              <p>
                <strong>Nom :</strong> {user.nomComplet}
              </p>
              <p>
                <strong>Sexe :</strong> {user.sexe}
              </p>
              <p>
                <strong>Niveau :</strong> {user.niveauScolaireOuAcadémique}
              </p>
              <p>
                <strong>Email :</strong> {user.emailAddress}
              </p>
              <p>
                <strong>Téléphone :</strong> {user.numéroDeTéléphone}
              </p>
              <p>
                <strong>Ville de résidence :</strong> {user.villeDeRésidence}
              </p>
              <p>
                <strong>Date de naissance :</strong> {user.dateDeNaissance}
              </p>
              <p>
                <strong>Source :</strong>{" "}
                {user["commentAvezVousEntenduParlerD'incHClass?"]}
              </p>
              <p>
                <strong>Inscrit pour la formation :</strong>{" "}
                {user["quelleFormationSouhaitezVousSuivre ?"]}
              </p>
            </div>

            <h3 className="text-center mb-4">
              Souhaitez-vous continuer avec la formation ?
            </h3>
            {/* Boutons Oui et Non */}
            <div className="d-flex justify-content-center gap-3">
              <button
                className="btn btn-success"
                onClick={() => handleResponse("oui")}
              >
                Oui
              </button>
              <button
                className="btn btn-danger"
                onClick={() => handleResponse("non")}
              >
                Non
              </button>
              {/* Bouton Retour */}
              <button className="btn btn-secondary" onClick={onBack}>
                Retour
              </button>
              <div className="text-center mt-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Validation des types des propriétés passées
Confirmation.propTypes = {
  user: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
};

export default Confirmation;
