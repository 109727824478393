import React, { useState } from "react";
import PropTypes from "prop-types";
import { fetchSheetData } from "../services/sheetService";
import "./style.css";

/**
 * Composant EmailInput : Gère la saisie et la vérification d'un utilisateur.
 * @param {function} onUserFound - Fonction pour gérer un utilisateur trouvé.
 */
const EmailInput = ({ onUserFound }) => {
  const [email, setEmail] = useState(""); // État pour stocker la saisie de l'utilisateur
  const [isLoading, setIsLoading] = useState(false); // État pour gérer le chargement
  const [error, setError] = useState(""); // État pour afficher les erreurs

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      const data = await fetchSheetData();
      const user = data.formResponses1.find(
        (row) =>
          row.emailAddress === email ||
          parseInt(row.numéroDeTéléphone) === parseInt(email)
        // (row) => parseInt(row.numéroDeTéléphone)
        // (row) => console.log(typeof row.numéroDeTéléphone)
      );

      if (user) {
        console.log("ok");
        onUserFound(user); // Passe l'utilisateur trouvé au parent
      } else {
        setError(
          <>
            Désolé, vous n'êtes pas inscrit.{" "}
            <a
              href="https://forms.gle/bwpMAfhXDvJ65YEB8"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cliquez ici pour vous inscrire
            </a>
            , puis revenez ici confirmer votre participation.
          </>
        );
      }
    } catch (err) {
      setError("Erreur lors de la vérification. Veuillez réessayer.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          {/* Formulaire */}
          <form onSubmit={handleSubmit} className="shadow p-4 rounded bg-white">
            <div className="row">
              <div className="col-4">
                <img id="logoInch" src="img/inchclass.png" alt="InchClass" />
              </div>
              <div className="col-6">
                <img id="logoOif" src="img/oif.png" alt="OIF" />
              </div>
            </div>

            <h4 className="text-center mb-4" style={{ color: "#632b84" }}>
              Formations professionnelles gratuites aux métiers du numérique
              offertes par l'organisation internationale de la francophonie
              (OIF)
            </h4>

            <h2
              className="text-center mb-4"
              style={{ color: "#1371b9", marginTop: "10%" }}
            >
              Confirmez votre participation
            </h2>
            <div className="mb-3">
              <label htmlFor="emailInput" className="form-label">
                Email
              </label>
              <input
                type="text"
                id="emailInput"
                className="form-control"
                placeholder="Saisissez votre email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            {/* Bouton de soumission */}
            <div className="d-grid">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
                style={{ backgroundColor: "#1371b9" }}
              >
                {isLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Vérification...
                  </>
                ) : (
                  "Vérifier"
                )}
              </button>
            </div>
            <p
              style={{ marginTop: "10%", textAlign: "center", marginLeft: "0" }}
            >
              © Copyright InchClass
            </p>
          </form>

          {/* Message d'erreur */}
          {error && (
            <div className="alert alert-danger mt-3" role="alert">
              {error}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

EmailInput.propTypes = {
  onUserFound: PropTypes.func.isRequired,
};

export default EmailInput;
