import axios from "axios"; // Import de la bibliothèque axios pour les requêtes HTTP

// URL de base pour l'API Sheety
const BASE_URL =
  "https://api.sheety.co/f2d663f6961c794b5d1c549c6b7373db/inscriptionFormationDClicOifCameroun (responses)/formResponses1";

/**
 * Fonction pour récupérer toutes les données de la feuille.
 * @returns {Promise<Array>} Les données de la feuille sous forme de tableau d'objets.
 */
export const fetchSheetData = async () => {
  const response = await axios.get(`${BASE_URL}`);
  return response.data; // Retourne les lignes sous forme de tableau
};

//

// Ajoute une nouvelle ligne dans le deuxième sheet
// export const addToSecondSheet = async (user, response) => {
//   const url =
//     "https://api.sheety.co/6308bcf04196f21db02eaeaa1774a18f/présenceRi/feuille1";

//   // Préparer les données pour le POST
//   const body = {
//     feuille1: {
//       timestamp: user.timestamp || new Date().toLocaleString(),
//       emailAddress: user.emailAddress,
//       nomComplet: user.nomComplet,
//       veuxContinuer: response, // Ajoute la réponse "oui" ou "non"
//       sexe: user.sexe,
//       dateDeNaissance: user.dateDeNaissance,
//       villeDeRésidence: user.villeDeRésidence,
//       quelleFormationSouhaitezVousSuivre:
//         user["quelleFormationSouhaitezVousSuivre ?"],
//       numéroDeTéléphone: user.numéroDeTéléphone,
//       niveauScolaireOuAcadémique: user.niveauScolaireOuAcadémique,
//     },
//   };

//   // Envoi des données via l'API Sheety
//   try {
//     const response = await fetch(url, {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(body),
//     });

//     // Vérification de la réponse
//     if (!response.ok) {
//       throw new Error(`Erreur : ${response.status}`);
//     }

//     const json = await response.json();
//     console.log("Données ajoutées avec succès :", json.feuille1);
//   } catch (error) {
//     console.error("Erreur lors de l'ajout au deuxième sheet :", error);
//     throw error;
//   }
// };

export const addToSecondSheet = async (user, response) => {
  const url =
    "https://api.sheety.co/6308bcf04196f21db02eaeaa1774a18f/présenceRi/feuille1";

  // Vérifier si l'utilisateur existe déjà dans le deuxième sheet
  try {
    // Récupérer toutes les données existantes
    const existingDataResponse = await axios.get(url);
    const existingData = existingDataResponse.data.feuille1;

    // Vérifier si l'utilisateur existe déjà
    const userExists = existingData.some(
      (entry) => entry.emailAddress === user.emailAddress
    );

    if (userExists) {
      // Si l'utilisateur a déjà confirmé, renvoyer un message
      return { alreadyConfirmed: true };
    }

    // Préparer les données pour le POST
    const body = {
      feuille1: {
        timestamp: user.timestamp || new Date().toLocaleString(),
        emailAddress: user.emailAddress,
        nomComplet: user.nomComplet,
        veuxContinuer: response, // Ajoute la réponse "oui" ou "non"
        sexe: user.sexe,
        dateDeNaissance: user.dateDeNaissance,
        villeDeRésidence: user.villeDeRésidence,
        quelleFormationSouhaitezVousSuivre:
          user["quelleFormationSouhaitezVousSuivre ?"],
        numéroDeTéléphone: user.numéroDeTéléphone,
        niveauScolaireOuAcadémique: user.niveauScolaireOuAcadémique,
      },
    };

    // Ajouter l'utilisateur au deuxième sheet
    const postResponse = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!postResponse.ok) {
      throw new Error(`Erreur : ${postResponse.status}`);
    }

    const json = await postResponse.json();
    return { alreadyConfirmed: false, data: json.feuille1 };
  } catch (error) {
    console.error("Erreur lors de l'ajout au deuxième sheet :", error);
    throw error;
  }
};
