import React, { useState } from "react"; // Import de React et du hook useState pour la gestion d'état
import EmailInput from "./components/EmailInput"; // Import du composant EmailInput
import Confirmation from "./components/Confirmation"; // Import du composant Confirmation

/**
 * Composant principal App : Gère le flux de l'application.
 */
const App = () => {
  // État pour stocker l'utilisateur actuel (null par défaut)
  const [currentUser, setCurrentUser] = useState(null);

  /**
   * Fonction appelée lorsque l'utilisateur est trouvé dans EmailInput.
   * @param {object} user - Les données de l'utilisateur trouvé.
   */
  const handleUserFound = (user) => {
    setCurrentUser(user); // Met à jour l'utilisateur actuel
  };

  /**
   * Fonction pour retourner à l'écran de saisie.
   */
  const handleBack = () => {
    setCurrentUser(null); // Réinitialise l'utilisateur actuel
  };

  return (
    <div className="App">
      {/* <h1>Confirmation de participation à la formation</h1> */}
      {/* Si aucun utilisateur n'est sélectionné, afficher EmailInput */}
      {!currentUser && <EmailInput onUserFound={handleUserFound} />}

      {/* Si un utilisateur est sélectionné, afficher Confirmation */}
      {currentUser && <Confirmation user={currentUser} onBack={handleBack} />}
    </div>
  );
};

export default App;
